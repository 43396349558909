import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"

import useIntersectionObserver from "./Hooks/UseIntersectionObserver"

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    overflow: "hidden",
    background: "rgba(0, 0, 0, 0)",
    marginBottom: theme.spacing(0),
    width: "100%",
    // height: "100%",
  },
  image: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    // height: "100%",
  },
  blur: {
    imageRendering: "pixelated",
    transform: "scale(1)",
    transition: "visibility 0ms ease 400ms",
  },
  thumbnail: {
    transition: "opacity 400ms ease 0ms",
  },
}))

const LazyImage = props => {
  const classes = useStyles(props)
  const { thumbnailUrl, imageUrl, width, height, title, alt } = props

  const aspectRatio = (height / width) * 100

  const ref = useRef()
  const [isVisible, setIsVisible] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)

  useIntersectionObserver({
    target: ref,
    onIntersect: ([{ isIntersecting }], observerElement) => {
      if (isIntersecting) {
        setIsVisible(true)
        observerElement.unobserve(ref.current)
      }
    },
  })

  return (
    <div
      ref={ref}
      className={classes.root}
      style={{ paddingBottom: `${aspectRatio}%` }}
    >
      {isVisible && (
        <React.Fragment>
          <img
            className={clsx(classes.image, classes.blur)}
            alt={`${alt} Thumbnail`}
            title={`${title} Thumbnail`}
            src={thumbnailUrl}
            style={{ visibility: isLoaded ? "hidden" : "visible" }}
          />
          <img
            onLoad={() => {
              setIsLoaded(true)
            }}
            className={clsx(classes.image, classes.thumbnail)}
            style={{ opacity: isLoaded ? 1 : 0 }}
            alt={alt}
            title={title}
            src={imageUrl}
          />
        </React.Fragment>
      )}
    </div>
  )
}

LazyImage.propTypes = {
  thumbnailUrl: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
}

export default LazyImage
