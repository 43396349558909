import React from "react"
import PropTypes from "prop-types"
import LazyImage from "../Constant/LazyImage"
import { Box, Typography, Grid, Container } from "@material-ui/core"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {},
  boxQuote: {
    backgroundColor: theme.palette.background.paper,
  },
  containerQuote: {
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(12, 0), // Padding for desktop and larger devices
    },
    [theme.breakpoints.between("sm", "md")]: {
      padding: theme.spacing(6, 0), // Padding for tablet devices
    },
    [theme.breakpoints.down("sm")]: {
      padding: 0, // Padding for mobile devices
    },
  },
  quote: {
    borderRadius: theme.spacing(0),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.palette.shadow.quote.normal,
    "-webkit-transition": "all 450ms cubic-bezier(0.15,1,0.45,1)",
    transition: "all 450ms cubic-bezier(0.15,1,0.45,1)",
    "&:hover": {
      boxShadow: theme.palette.shadow.quote.hover,
    },
  },
}))

const Quote = props => {
  const classes = useStyles(props)
  const { quote } = props

  return (
    <div className={classes.root}>
      <Box className={classes.boxQuote}>
        <Container
          className={classes.containerQuote}
          component="div"
          maxWidth="sm"
        >
          <Box className={classes.quote}>
            <LazyImage
              thumbnailUrl={`https://gaudeamus.citatecarti.ro/media/Cartolina ${quote.number}.jpg`}
              imageUrl={`https://gaudeamus.citatecarti.ro/media/Cartolina ${quote.number}.jpg`}
              width={1080}
              height={1622}
              title={quote.quote}
              alt={quote.quote}
            />
          </Box>
        </Container>
      </Box>

      <Container component="div" maxWidth="sm" style={{ marginTop: 96 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={12}>
            <Box style={{ marginBottom: 32, textAlign: "center" }}>
              <Typography variant="h4">“{quote.quote}”</Typography>
            </Box>

            <Box style={{ marginBottom: 32, textAlign: "center" }}>
              <Typography variant="h6" style={{ marginBottom: 16 }}>
                AUTOR
              </Typography>

              <Typography variant="subtitle2">{quote.author}</Typography>
            </Box>

            <Box style={{ marginBottom: 128, textAlign: "center" }}>
              <Typography variant="h6" style={{ marginBottom: 16 }}>
                GÂNDURI
              </Typography>
              <Typography variant="subtitle2">{quote.commentary}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

Quote.propTypes = {
  quote: PropTypes.object,
}

export default Quote
