import React from "react"
import Seo from "../seo"
import Root from "../Root"
import Quote from "./Quote"

const QuotePage = ({ pageContext }) => {
  const { quote } = pageContext

  return (
    <Root>
      <Seo title="Citate din cărți românești, străine pentru toată lumea." />
      <Quote quote={quote} />
    </Root>
  )
}

export default QuotePage
